<template>
  <div>
    <GlobalForm class="global-form" :needBtnLoading="true" :init-data="initData" :form-item-list="formItemList"
      :inline="true" :labelWidth="100" round @handleConfirm="handleConfirm" confirmBtnName="查询">
      <el-form-item class="el_from" label="文案类型" slot="operationSelect" style="margin:0">
        <el-select v-model="copyType" placeholder="请选择" clearable filterable @change="selecthandleChange">
          <el-option v-for="item in shanpsSelectList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <template>
        <el-button size="small" type="warning" @click="detectionSet">商户下载数据排行
        </el-button>
        <el-button size="small" type="primary" icon="el-icon-plus" @click="addOneMenu">创建文案
        </el-button>
        <el-button size="small" type="success" @click="openEcourageDialog">首页激励语配置
        </el-button>
      </template>
    </GlobalForm>
    <!-- 创建文案 -->
    <el-dialog title='创建文案' :visible.sync="csetionShow" :close-on-click-modal="false" width="700px">
      <el-form :model="createinitData" :rules="createformRules" ref="ruleForm" label-width="106px">
        <el-form-item label="特殊资源:" label-width="110px" prop="copyType">
          <el-radio-group v-model="createinitData.copyType">
            <el-radio label="00">线上回收引流</el-radio>
            <el-radio label="01">每日文案</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="海报图片:" label-width="110px" prop="posterImg">
          <ElImgUpload :exist-image="createinitData.posterImg" @handleDelete="handleUploadDelete"
            @handleChange="handleUploadChange($event)" />
          <span class="tip">最多上传1张图片，大小不超过2M，格式为JPG或PNG</span>
        </el-form-item>
        <el-form-item label="分享文案内容:" label-width="110px" prop="content">
          <el-input autosize :maxlength="200" type="textarea" v-model="createinitData.content"
            placeholder="请输入文案内容，最多200字" clearable>
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="resetForm('ruleForm')">取 消</el-button>
        <el-button size="small" type="primary" :loading="btnLoading" @click="submitAdd('ruleForm')">确认</el-button>
      </span>
    </el-dialog>
    <!--下载数据排行  -->
    <el-dialog class="allot-dialog" title="下载数据排行" :visible.sync="handleShow" :close-on-click-modal="false"
      width="700px">
      <div style="display: flex;align-items: center;height: 50px;margin-bottom: 20px;">
        <div class="andleShowbox">
          <div :class="[
            [rankType !== '01' ? 'andleShowcolor andleShowstyle' : 'andleShowstyle'],
          ]" @click="switchTrigin('02')">
            按下载量排行
          </div>
          <div :class="[
            [rankType == '01' ? 'andleShowcolor andleShowstyle' : 'andleShowstyle'],
          ]" @click="switchTrigin('01')">
            按完成率排行
          </div>
        </div>
        <div class="time-select">
          <div class="btn" :class="dateType == 'today' ? 'btnType2' : 'btnType-after2'" @click="dataObtain('today')">
            今日
          </div>
          <div class="btn" :class="dateType == 'yesterday' ? 'btnType2' : 'btnType-after2'"
            @click="dataObtain('yesterday')">
            昨日
          </div>
          <!-- <div class="btn" :class="dateType == '05' ? 'btnType2' : 'btnType-after2'" @click="dataObtain('05')">
            本月
          </div> -->
        </div>
        <div style="height: 30px;">
          <GlobalForm class="global-form" ref="redDialogForm" :init-data="downloadinitData"
            :form-item-list="redenvelopeslist" :inline="true" :labelWidth="100" round
            @handleConfirm="redenvelopeslistFn" confirmBtnName="查询">
          </GlobalForm>
        </div>
      </div>

      <GlobalTable ref="GlobalTable" v-loading="loading3" :columns="redenveTabels" :data="redenvedata"
        :currentPage="page3.pageNum" :isIndex="false" :total="page3.total" @handleCurrentChange="redenvellistFn">
        <el-table-column type="index" slot="ranking" :index='(index) => { return (index + 1) }' label="排行"
          align="center"></el-table-column>
        <el-table-column label="完成率" slot="downloadRate" align="center">
          <template slot-scope="{ row }">
            <span>{{ row.downloadRate || 0 }}%</span>
          </template>
        </el-table-column>
        <!-- <el-table-column width="250" label="操作" slot="option" align="center">
          <template slot-scope="{ row }">
            <el-button size="mini" type="primary" round @click="handleBtn(row)">查看数据</el-button>
          </template>
        </el-table-column> -->
      </GlobalTable>
    </el-dialog>
    <!--激励语对话框-->
    <el-dialog class="allot-dialog1" title="首页激励语配置" :visible.sync="encouragDialogVisible" :close-on-click-modal="false"
      width="1000px">
      <div style="display: flex;align-items: center;height: 50px;margin-bottom: 20px;">
        <el-button size="small" type="primary" icon="el-icon-plus" @click="addEcouargeDialog">添加
        </el-button>
      </div>
      <el-table ref="singleTable" :data="tableData" border stripe max-height="600">
        <el-table-column align="center" label="序号" type="index" width="50">
        </el-table-column>
        <el-table-column align="center" prop="motivationalLanguage" label="激励语" width="300">
          <template slot-scope="{ row }">
            <div>{{ row.motivationalLanguage }}</div>
            <el-tag size="mini" type="warning" v-if="row.isUsed">使用中</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="createBy" label="添加人" width="200">
          <template slot-scope="{ row }">
            <div v-if="row.createByName&&row.createByAccount">{{ row.createByName }}-{{ row.createByAccount}}</div>
            <div v-else>- -</div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="createTime" label="添加时间" width="250">
          <template slot-scope="{ row }">
            <div>{{ row.createTime}}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="{ row }">
            <span :class="row.isUsed ? 'balackfz' : 'redfz'" style="margin-right: 10px;"
              @click="use(row)">选择本条使用</span><span :class="row.isUsed ? 'balackfz' : 'redfz'"
              @click="deleteEcourage(row)">删除</span>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <!--激励语添加表单-->
    <el-dialog class="allot-dialog" title="添加激励语" :visible.sync="addEncouragDialogVisible" :close-on-click-modal="false"
      width="500px" @close="closeAddEncouragDialogVisible">
      <div class="command">
        <div style="width: 88px">激励语</div>
        <el-input v-model="encourage" placeholder="请输入文字内容,最多12个字" maxlength="12"></el-input>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeAddEncouragDialogVisible">取 消</el-button>
        <el-button type="primary" @click="confrim">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ElImgUpload from "../../../components/global/components/elements/upload-element";
import moment from 'moment'
import _api from "@/utils/request";
import { _http } from "../../../utils/request/Motivational";
export default {
  components: { ElImgUpload },
  data() {
    return {
      rankType: "02",
      queryDate: "",
      dateType: "today",
      handleShow: false,
      isFrozen: true,
      downloadinitData: {
        entryTime: '',//moment().format("YYYY-MM-DD 23:59:59")
      },
      page3: {
        pageNum: 1,
        pageSize: 10,
      },
      redenvedata: [],
      loading3: false,
      redenvelopeslist: [
        {
          key: "entryTime",
          type: "date",
          // labelName: "账单时间",
          clearable: true,
          placeholder: "请选择时间",
        },
        {
          slotName: "botton",
        },
      ],
      redenveTabels: [
        { slotName: "ranking" },
        { label: "门店商户", prop: "companyName" },
        // { label: "下载量", prop: "storeName" },
        { label: "总人数", prop: "staffNum" },
        { label: "完成人数", prop: "download" },
        { slotName: "downloadRate" }, // 完成率
        { slotName: "option" }, // 操作
      ],
      btnLoading: false,
      csetionShow: false,
      iid: null,
      initData: {
        entryTime: [moment().day(moment().day() - 30).format('YYYY-MM-DD 00:00:00'), moment().format('YYYY-MM-DD 23:59:59')]
      },
      createinitData: {
        posterImg: "",
        content: "",
        copyType: '00'
      },
      copyType: '',
      shanpsSelectList: [{
        name: "线上回收引流",
        id: "00",
      }, {
        name: "每日文案",
        id: "01",
      }],
      pickerMinDate: null,
      pickerMaxDate: null,
      day31: 30 * 24 * 3600 * 1000,
      createformRules: {
        copyType: [
          { required: true, message: '请选择活动资源', trigger: 'change' }
        ],
        content: [
          {
            required: true,
            trigger: "blur",
            message: "请输入分享文案内容",
            max: 200,
          },
        ],
        posterImg: [
          {
            required: true,
            trigger: "blur",
            message: "请上传海报图片",
          },
        ],
      },
      formItemList: [
        { slotName: "operationSelect" },
        {
          key: "copyNo",
          type: "input",
          labelName: "文案编号",
          placeholder: "请输入文案编号",
        },
        {
          key: "entryTime",
          type: "pickerOptions",
          labelName: "时间",
          clearable: true,
          placeholder: "请选择时间",
          valueFormat: "timestamp",
          pickerOptions: {
            onPick: ({ maxDate, minDate }) => {
              if (minDate && this.pickerMinDate) {
                this.pickerMinDate = null;
              } else if (minDate) {
                this.pickerMinDate = minDate.getTime();
              }
            },
            disabledDate: (time) => {
              if (this.pickerMinDate) {
                return (
                  time.getTime() > this.pickerMinDate + this.day31 ||
                  time.getTime() < this.pickerMinDate - this.day31
                );
              }
              return false;
            },
          },
        },
        { slotName: 'AddSlot' }
      ],
      encouragDialogVisible: false,//激励语弹框
      addEncouragDialogVisible: false,//增加激励语表单对话框
      tableData: [],//激励语表格数据
      isuseing: true,
      encourage: "",//激励语
      dialogVisible: false,//通用弹框
      dialogWidth: 380,
      sureLoading: false,
    };
  },
  created() {
    this.handleConfirm(this.initData)
    this.getMotivationalList()
  },
  filters: {
    format(value) {//时间过滤
      if (!value) return '';
      return moment(value).format('YYYY-MM-DD HH:mm:ss')
    }
  },
  mounted() { },
  watch: {
    downloadinitData: {
      handler(newValue, oldValue) {
        // 处理对象变化
        console.log(newValue);

      },
      deep: true

    },
  },
  methods: {
    switchTrigin(type) {
      this.rankType = type
      this.redenvellistFn();
    },
    // handleBtn() {

    // },
    dataObtain(way) {
      this.dateType = way;
      this.downloadinitData.entryTime = ''
      this.redenvellistFn();
    },
    // 下载数据排行
    redenvelopeslistFn(data) {
      console.log(data);
      let redenvelopeslist = {};
      if (!data.entryTime) {
        this.queryDate = ""
        if (!this.dateType) {
          this.dateType = "today"
        }
      } else {
        this.dateType = ""
        this.queryDate = moment(data.entryTime).format("x")
      }
      redenvelopeslist = {
        queryDate: this.queryDate,
        dateType: this.dateType,
        rankType: this.rankType,
        pageNum: 1,
        pageSize: 10,
      };
      this.loading3 = true;
      _api
        .copyCompanyRank(redenvelopeslist)
        .then((res) => {
          this.redenvedata = res.data.records;
          this.page3.pageNum = res.data.current;
          this.page3.total = res.data.total;
        })
        .finally(() => {
          this.loading3 = false;
        });
    },
    // 下载数据排行
    redenvellistFn(val) {
      if (val) {
        this.page3.pageNum = val;
      } else {
        this.page3.pageNum = 1;
      }
      let params = {
        dateType: this.dateType,
        rankType: this.rankType,
        queryDate: this.queryDate,
        pageNum: this.page3.pageNum,
        pageSize: this.page3.pageSize || 10,
      };

      this.loading3 = true;
      _api.copyCompanyRank(params).then((res) => {
        if (res.code === 1) {
          this.redenvedata = res.data.records;
          this.page3.pageNum = res.data.current;
          this.page3.total = res.data.total;
          console.log(res, "~~~~~~~~~~~~~~~~~~~~~~~~~~~");
        }
        this.loading3 = false;
      });
    },
    //商户下载数据排行
    detectionSet() {
      this.redenvellistFn();
      this.handleShow = true
    },
    addOneMenu() {
      this.csetionShow = true
    },
    // 确认添加文案
    submitAdd(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.btnLoading = true
          console.log(this.createinitData);
          _api.addcopyall(this.createinitData).then(res => {
            if (res.code === 1) {
              this.csetionShow = false
              this.$message({
                message: '添加成功！',
                type: 'success'
              });
              this.handleConfirm();
              this.createinitData = {
                "content": "",
                "posterImg": ""
              }
            }
            this.btnLoading = false
          }).catch(err => {
            this.btnLoading = false
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });

    },
    //删除图片
    handleUploadDelete() {
      this.createinitData.posterImg = "";
    },
    // 上传图片后，将图片信息保存到对应参数中
    handleUploadChange(url) {
      this.createinitData.posterImg = url;
    },
    //添加文案弹窗关闭
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.csetionShow = false
      this.createinitData = {
        "content": "",
        "posterImg": ""
      }
    },
    handleConfirm(data, cd) {
      console.log(data, this.copyType);
      let SeachParams = {}
      if (data) {
        let startTime = "";
        let endTime = "";
        if (data.entryTime === null) {
          startTime = '';
          endTime = '';
        } else {
          startTime = moment(data.entryTime[0]).format("x");
          endTime = moment(data.entryTime[1]).format("x");
        }
        SeachParams = {
          addStartTime: startTime,
          addEndTime: endTime,
          copyType: this.copyType || "",
          copyNo: data.copyNo || "",
          pageNum: 1,
          pageSize: 10,
        };
      }
      SeachParams.disuseLoding = () => {
        if (typeof cd === 'function') {
          cd();
        }
      },
        this.$store.commit("tagsView/SEACH_PARAMS", SeachParams);
    },
    selecthandleChange(e) {
      this.iid = e;
    },
    //打开激励语弹框
    openEcourageDialog() {
      this.encouragDialogVisible = true
    },
    //打开添加弹框
    addEcouargeDialog() {
      this.addEncouragDialogVisible = true
    },
    //关闭添加弹框
    closeAddEncouragDialogVisible() {
      this.addEncouragDialogVisible = false
      this.encourage = ""
    },
    //最后提交添加的激励语言
    confrim() {
      if (!this.encourage) {
        this.addEncouragDialogVisible = false
      }
      else {
        console.log(111)
        let params = {
          motivationalLanguage: this.encourage
        }
        _http.dispatchMotivational(params).then(res => {
          if (res.code == 1)
            this.$message.success("添加激励语成功")
          this.addEncouragDialogVisible = false
          this.encourage = ""
          this.getMotivationalList()
        }).catch(err => {
          this.addEncouragDialogVisible = false
          this.encourage = ""
        })
      }
    },
    //使用某条激励语言
    use(row) {
      if (row.isUsed) {
        return
      }
      else {
        this.$confirm('是否确认选择使用本条数据?', '选择提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          _http.diapatchUseMotivational({ id: Number(row.id) }).then(res => {
            if (res.code == 1) {
              this.$message({
                type: 'success',
                message: '操作成功!'
              });
            }
            this.getMotivationalList()
          }).catch(err => {
          })
        }).catch(() => {
        });
      }
    },
    //删除某条激励语言
    deleteEcourage(row) {
      let conten = `确认要删除本条的全部设置数据吗?`
      if (row.isUsed) {
        return
      }
      else {
        this.$confirm(conten, '删除提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          _http.diapatchDeleteMotivational({ id: Number(row.id) }).then(res => {
            if (res.code == 1) {
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
            }
            this.getMotivationalList()
          }).catch(err => {
          })
        }).catch(() => {
        });
      }
    },
    //获取激励语列表
    getMotivationalList() {
      _http.diapatchGetMotivationalList().then(res => {
        console.log(res, 112333)
        if (res.code == 1) {
          this.tableData = res.data
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.global-form {
  /deep/.el-input__inner {
    width: 160px;
  }
}

.tip {
  color: #FF687B;
}

.time-select {
  display: flex;
  margin-left: 20px;

  .btn {
    box-shadow: 2px 1px 10px 0px rgba(63, 44, 245, 0.2);
    margin: 0px 10px 0px 0;
    text-align: center;
    cursor: pointer;
  }

  .btnType-after2 {
    // width: 33%;
    width: 80px;
    min-width: max-content;
    height: 30px;
    background: #ffffff;
    color: #0981ff;
    line-height: 30px;
    border-radius: 20px;
  }

  .btnType2 {
    width: 80px;
    min-width: max-content;
    height: 30px;
    background: #0981ff;
    color: white;
    line-height: 30px;
    border-radius: 20px;
  }
}

.andleShowbox {
  border: 1px solid #0981ff;
  width: 200px;
  display: flex;
  justify-content: space-between;
  border-radius: 4px;

  .andleShowstyle {
    width: 100px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #0981ff;
    cursor: pointer;
    user-select: none;
  }

  .andleShowcolor {
    background-color: #0981ff;
    color: #fff;
  }
}

.balackfz {
  font-size: 12px;
  color: #666;
}

.redfz {
  font-size: 12px;
  color: #ff7284;
  cursor: pointer;
}

.command {
  display: flex;
  align-items: center;
}

/deep/.el-dialog__body {
  padding-bottom: 20px !important;
}

.offline_box {
  width: 100%;

  >h2 {
    color: #ff687b;
    font-size: 12px;
    font-weight: 900;
  }

  >p {
    font-size: 14px;
    margin: 20px 0;
    color: #333;
    text-align: center;
  }

  .cancel_offline_note {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #f2f2f2;
    height: 55px;
  }
}
</style>
