import request from './request'

export const _http = {
    //新增激励语
    dispatchMotivational(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/copy/addMotivational',
            data:params
        })
    },
    //获取激励语列表
    diapatchGetMotivationalList(){
        return request({
            method: 'POST',
            url: '/platform/pc/copy/motivationalList',
        })
    },
    //删除激励语
    diapatchDeleteMotivational(params){
        return request({
            method: 'GET',
            url: '/platform/pc/copy/deleteMotivational',
            params:params
        })
    },
    //使用激励语
    diapatchUseMotivational(params){
        return request({
            method: 'GET',
            url: '/platform/pc/copy/updateChooseMotivational',
            params:params
        })
    },
}